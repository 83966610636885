.field-content {
  .appt-book {
    .my-appointments {
      .appointments-container {
        .section-header {
          margin-top: 5%;
          .section-header__inner {
            font-weight: 400;
          }
        }
      }
      .appt-book-content-header {
        margin-top: 5%;
      }
    }
  }
}
