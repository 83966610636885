.opc__cart {
  .cr21-refresh {
    .header-search {
      .header-search {
        &__icon svg {
          height: 53px;
        }
      }
    }
    .site-header-formatter {
      &__utilities-content {
        .site-my-mac {
          &__link-icon {
            height: 66px;
          }
        }
      }
    }
  }
}
